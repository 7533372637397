import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { table, row, cell } from './nutrition-table.module.scss';

import { IProductDetails } from '../../models/product-details';

interface INutritionTableProps {
    productData: IProductDetails;
}

const NutritionTable: React.FC<INutritionTableProps> = ({ productData }) => {
    const { t } = useI18next();

    return (
        <div className={table}>
            <div className={row}>
                <div className={cell} />
                <div className={cell}>{t('product.details.nutrition.group.small')}</div>
                <div className={cell}>{t('product.details.nutrition.group.large')}</div>
            </div>
            {Object.entries(productData.nutritions).map(([name, portions]) => (
                <div className={row} key={name}>
                    <div className={cell}>{t(`product.details.nutrition.${name}`)}</div>
                    <div className={cell}>{portions.small}</div>
                    <div className={cell}>{portions.large}</div>
                </div>
            ))}
        </div>
    );
};

export default NutritionTable;
