import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { table, row, cell } from './portion-table.module.scss';

const PortionTable = () => {
    const { t } = useI18next();

    return (
        <div className={table}>
            <div className={row}>
                <div className={cell}>{t('portion.table.capacity.label')}</div>
                <div className={cell}>{t('portion.table.capacity.100')}</div>
                <div className={cell}>{t('portion.table.capacity.200')}</div>
                <div className={cell}>{t('portion.table.capacity.500')}</div>
                <div className={cell}>{t('portion.table.capacity.700')}</div>
                <div className={cell}>{t('portion.table.capacity.1000')}</div>
            </div>
            <div className={row}>
                <div className={cell}>{t('portion.table.portions.label')}</div>
                <div className={cell}>{t('portion.table.portion.100')}</div>
                <div className={cell}>{t('portion.table.portion.200')}</div>
                <div className={cell}>{t('portion.table.portion.500')}</div>
                <div className={cell}>{t('portion.table.portion.700')}</div>
                <div className={cell}>{t('portion.table.portion.1000')}</div>
            </div>
        </div>
    );
};

export default PortionTable;
