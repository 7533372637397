import React from 'react';
import { graphql } from 'gatsby';

import { layout, section } from './product-details-page.module.scss';

import { IPage } from '../models/page.model';

import MainLayout from '../layouts/main-layout';
import ProductDetails from '../components/organisms/product-details';

interface IProductDetailsPage {
    readonly data: {
        page: IPage | null;
    };
}

const ProductDetailsPage: React.FC<IProductDetailsPage> = ({ data }) => {
    const { page } = data;

    return (
        <MainLayout
            className={layout}
            page={page}
            showRecipesSlider={false}
            includeHeaderPadding={true}
        >
            <ProductDetails className={section} />
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default ProductDetailsPage;
