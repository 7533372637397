import React, { useLayoutEffect } from 'react';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { RouteComponentProps } from '@reach/router';
import { useMutation } from 'react-query';
import { getPathFromSlug } from '../../../plugins/gatsby-plugin-ap-i18next/src/get-path-from-slug';

import {
    wrapper,
    image,
    imageRatio,
    group,
    title,
    large,
    text,
    disclaimer,
    disclaimerImage,
    link,
} from './product-details.module.scss';

import { getProduct } from '../../api-ssr/products';
import { IProductDetails } from '../../models/product-details';

import RatioImage from '../atoms/ratio-image';
import NutritionTable from '../molecules/nutrition-table';
import Loader from '../atoms/loader';
import PortionTable from '../molecules/portion-table';
import useQueryParam from '../../hooks/use-query-param';

const bansImageSrc = '../../assets/images/alcohol-bans.png';

interface IProductDetailsProps extends RouteComponentProps {
    className?: string;
}

const ProductDetails: React.FC<IProductDetailsProps> = ({ className = '' }) => {
    const { t, language } = useI18next();
    const nameQuery = useQueryParam('n');
    const name = nameQuery.liveValues[0] || '';

    if (!name && typeof window !== 'undefined') {
        navigate(getPathFromSlug('/produkty/', language));
    }

    const productMutation = useMutation(() => getProduct(name || '', language), {
        onError: () => {
            navigate(getPathFromSlug('/produkty/', language));
        },
    });

    const { data, isSuccess, isError } = productMutation;

    useLayoutEffect(() => {
        if (name) productMutation.mutate();
    }, []);

    const productData: IProductDetails = data?.data;

    return (
        <>
            {(!isSuccess || isError) && <Loader />}
            {!isError && isSuccess && productData && (
                <div className={`${wrapper} ${className}`}>
                    <div>
                        <RatioImage
                            className={image}
                            ratioClass={imageRatio}
                            src={productData.media[0].url}
                            objectFit={'contain'}
                        />
                    </div>
                    <div>
                        <div className={group}>
                            <h1 className={`${title} ${large}`}>{productData.name}</h1>
                        </div>
                        <div className={group}>
                            <p className={title}>{t('product.details.ingredients.title')}</p>
                            <p className={text}>{productData.ingredients}</p>
                        </div>
                        <div className={group}>
                            <p className={title}>{t('product.details.nutrition.title')}</p>
                            <NutritionTable productData={productData} />
                        </div>
                        <div className={group}>
                            <PortionTable />
                        </div>
                        <div className={disclaimer}>
                            <StaticImage
                                className={disclaimerImage}
                                alt={t('product.details.disclaimer.image.alt')}
                                src={bansImageSrc}
                            />
                            <p>
                                <span>{t('product.details.disclaimer.info.main')}</span>{' '}
                                <a
                                    className={link}
                                    href="https://pijodpowiedzialnie.pl/"
                                    target={'_blank'}
                                    rel="noopener noreferrer"
                                >
                                    {t('product.details.disclaimer.info.link')}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ProductDetails;
